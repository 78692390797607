.navbar-menu-content {
    max-width: 100%;
    margin: 0 auto;

    .navbar-content-row {
        min-height: 100vh
    }

    &__left {
        @include flex-center;

        background-size: cover !important;
        position: relative;
        z-index: 9;
        @include for-max-991 {
            padding: 10px 20px !important;
            background-size: auto;
            min-height: 180px;
        }
        @include for-max-1199 {
            min-height: 280px;
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background: #0b0b0b30;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }

        .menu-list-item {
            display: flex;
            flex-direction: column;
            @include for-max-991 {
                flex-direction: row;
            }

            .item-list {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: white;
                color: $black;
                font-size: 28px;
                @include flex-center;
                margin-bottom: 20px;
                font-weight: bold;
                position: relative;
                @include for-max-991 {
                    margin-bottom: 0;
                    margin-right: 20px;
                    width: 40px;
                    height: 40px;
                    font-size: 20px;
                }

                &::after {
                    content: '';
                    width: 1px;
                    height: 30px;
                    background: $white;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    left: 0;
                    margin: auto;
                    @include for-max-991 {
                        width: 30px;
                        height: 1px;
                        left: 100%;
                        top: 0;
                        bottom: 0;
                    }

                }

                &.active {
                    background: $green;
                    color: $white;

                    &::after {
                        background: $green;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                    @include for-max-991 {
                        margin-right: 0;
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &__right {
        padding: 60px 80px 60px 60px;
        display: flex;
        flex-direction: column;
        background: #F4F3E7;

        @include for-max-575 {
            padding: 20px;
        }

        .right-content-width {
            max-width: 448px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

.logo-image {
    width: fit-content;

    img {
        width: 125px;
    }
}

.title-small {
    font-size: 22px;
    @include font-bold;
    @include text-dark;
}

.title-big {
    font-size: 34px;
    @include font-bold;
    @include text-dark;
}


.tooltip-inner {
    background-color: $danger;
    right: -180px !important;
    z-index: 99 !important;
    position: relative;
    @include for-max-575 {
        right: -80px !important;
    }
}

.box-content {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    &__box {
        padding: 20px 20px 10px 20px;
        border-radius: 8px;

        p, h4 {
            color: $white;
        }

        h4 {
            font-size: 23px;
            @include font-bold;
            margin-top: 4px;
        }

        .left-title-1 {
            font-size: 24px;
            margin-bottom: 16px;
        }

        .left-value-1 {
            font-size: 20px;
            color: rgba(255, 255, 255, 0.6);
            font-weight: bold;
        }

        .left-value-2 {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.6);
            font-weight: bold;
            margin-bottom: 0;
        }

        small {
            font-size: 10px;
            color: $primary;
        }

        .left-subtitle-1 {
            font-size: 11px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            display: block;
            margin-top: 16px;
        }

        .left-subtitle-2 {
            font-weight: bold;
        }

        p {
            opacity: .85;
            font-size: 12px;
            margin-bottom: 0;
        }

        &:nth-child(2) {
            p {
                font-size: 12px;
                margin-bottom: 0;

                &:nth-child(1) {
                    font-size: 25px;
                    margin-bottom: 10px;
                }

                &:nth-child(2) {
                    font-size: 25px;
                    @include font-bold;
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.opacity-50 {
    opacity: .50;
}

.progress-slide-wrap {
    background: #F0F0F0;
    border-radius: 20px;
    width: 100%;
    height: 8px;
    position: relative;

    .progress-slide-bar {
        background: $primary;
        border-radius: 20px;
        height: 8px;
    }
}

.truck-image {
    @include for-max-991 {
        margin-top: 60px;
    }
}

.fast_track_block_page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 575px) {
    .title-small {
        font-size: 18px;
    }
}

.ft-tearm-and-condition {
    position: absolute;
    z-index: 1000;
    border: 1px solid $green;
    background-color: #ffffff;
    min-height: 226px;
    top: 280px;
    max-width: 380px;
    right: 0;
    left: 0;
    margin: 0 auto;
    font-size: 16px;
    overflow: auto;
    max-height: 500px;

    .ft-tearm-header {
        border-bottom: 1px solid #e0e4e7;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 14px;
        padding-right: 20px;

        .ft-title {
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
        }

        .ft-terms-close {
            display: inline-block;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .ft-terms-content {
        padding: 14px;
    }

}

.v-suggestions {
    padding: 0;

    input {
        width: 100%;
        height: 100%;
        border: transparent;
        border-radius: 4px;
        padding: 0 12px;

        &:focus,
        &:focus-within {
            outline: none;
        }
    }

    .suggestions {
        max-height: 150px;
        overflow-y: scroll;
        top: 44px !important;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #e3e9dd;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #68864C;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #68864C transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

.icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
}

.input-loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
    top: 14px;
}

@keyframes around {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

.input-loader::after, .input-loader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #68864C #68864C transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
}

.input-loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
}

.button-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: #F29A06 !important;
}
