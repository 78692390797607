// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Home Page
$primary: #68864C;
$green: #104E24;
$white: white;
$black: #000000;
$dark: hsl(0, 0%, 13%);
$input-border: #d2d2d2;
$light: #E8E8E8;
$danger: #D35151;
$deep-green: #304F2B;
