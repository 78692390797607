@mixin for-max-575 {
    @media (max-width: 575px) { @content; }
  }
  @mixin for-max-991 {
    @media (max-width: 991px) { @content; }
  }
  @mixin for-max-1199 {
    @media (max-width: 1199px) { @content; }
  }
  @mixin for-max-1399 {
    @media (max-width: 1399px) { @content; }
  }


  @mixin for-min-768 {
    @media (min-width: 768px) { @content; }
  }
