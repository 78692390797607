.group {
    margin-bottom: 32px;
    .group-input {
        border-radius: 4px;
        height: calc(1.5em + 0.75rem + 6px);
        border-color: $input-border;
    }
    .group-label {
        @include font-bold;
        @include text-dark;
    }
}
.form-content {
    .group {
        margin-bottom: 16px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.input-exaple {
    position: absolute;
    top: -11px;
    left: 20px;
    background: white;
    padding: 0 5px;
    font-size: 14px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    &-content {
        width: 50%;
        position: relative;
        margin-bottom: 16px;
        .checkbox-group-input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 9;
            cursor: pointer;
            &:checked {
                ~.checkbox-group-label {
                    &::before {
                        background: $primary;
                        border-color: $primary;
                    }
                    &::after {
                        display: block;
                    }
                }
            }
        }
        .checkbox-group-label {
            position: relative;
            padding-left: 34px;
            margin-bottom: 0;
            cursor: pointer;
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                border: 1px solid $input-border;
                position: absolute;
                left: 0;
                top: 3px;
                border-radius: 4px;
            }
            &::after {
                content: '';
                display: inline-block;
                transform: rotate(45deg);
                height: 12px;
                width: 6px;
                border-bottom: 2px solid white;
                border-right: 2px solid white;
                position: absolute;
                left: 7px;
                top: 6px;
                display: none;
            }
        }
    }
}

.accept-terms {
    position: relative;
    width: fit-content;
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
        cursor: pointer;
        &:checked {
            ~label {
                &::before {
                    background: $primary;
                    border-color: $primary;
                }
                &::after {
                    display: block;
                }
            }
        }
    }
    label {
        position: relative;
        padding-left: 34px;
        margin-bottom: 0;
        cursor: pointer;
        &::before {
            content: '';
            width: 20px;
            height: 20px;
            border: 1px solid $input-border;
            position: absolute;
            left: 0;
            top: 3px;
            border-radius: 0px;
        }
        &::after {
            content: '';
            display: inline-block;
            transform: rotate(45deg);
            height: 12px;
            width: 6px;
            border-bottom: 2px solid white;
            border-right: 2px solid white;
            position: absolute;
            left: 7px;
            top: 6px;
            display: none;
        }
        a {
            position: relative;
            z-index: 999;
            color: $primary;;
        }
    }
}
