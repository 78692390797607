
body {
    font-family: 'Lato', sans-serif;
}
// background
.bg-primary {
    background: $primary !important;
}
.bg-green {
    background: $deep-green;
}

// color
.text-dark {
    color: $dark !important;
}
