.button {
    padding: 6px 20px;
    border: 1px solid;
    outline: none;
    @include font-bold;
    border-radius: 4px;
    &-primary {
        background: $primary;
        color: $white;
        border-color: $primary;
    }
    &-light {
        background: $light;
        border-color: $light;
    }
}
