// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito&family=Lato:ital,wght@0,400;0,700;0,900;1,300&display=swap');

// Variables
@import 'variables';
@import "./media-queries";
@import "./mixin";
@import "./button";
@import "./form";
@import "./utilities";
// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "/public/css/style.css";

@import "./stepper";



