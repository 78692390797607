@mixin text-dark {
    color: $dark;
}
@mixin font-bold {
    font-weight: bolder;
}
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
